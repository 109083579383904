<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="size-5"
    viewBox="0 0 20 20"
  >
    <path
      fill-rule="evenodd"
      d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-7-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 9a.75.75 0 0 0 0 1.5h.253a.25.25 0 0 1 .244.304l-.459 2.066A1.75 1.75 0 0 0 10.747 15H11a.75.75 0 0 0 0-1.5h-.253a.25.25 0 0 1-.244-.304l.459-2.066A1.75 1.75 0 0 0 9.253 9H9z"
      clip-rule="evenodd"
    />
  </svg>
</template>
